import { Component, ElementRef, ViewChild } from '@angular/core';
import { SpinnerService } from './services/spinner.service';
import { DataService } from './services/data.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, combineLatest, filter, map } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
    public imageTemplate = '';
    public spinnerText$ = this.spinner.getSpinnerObservable();
    public encryptedToken = '';
    public isGiveawayActive$ = this.isGiveawayActiveSimulatorScreen$();

    get isFirstScreen$(): Observable<boolean> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => {
                const url = this.router.url;
                const segments = url.split('/');
                return segments[segments.length - 1];
            }),
            map((url) => url.includes('home')),
        );
    }

    constructor(
        private spinner: SpinnerService,
        private dataService: DataService,
        private router: Router,
    ) { }

    @ViewChild('loadingImage', { static: true }) set loadingImage(image: ElementRef<HTMLImageElement>) {
        this.imageTemplate = image.nativeElement.outerHTML;
        image.nativeElement.style.display = 'none';
    }

    private isGiveawayActiveSimulatorScreen$(): Observable<boolean> {
        return combineLatest([this.dataService.getIsGiveawayActive$(), this.isFirstScreen$]).pipe(
            map(([active, firstScreen]) => active && firstScreen),
        );
    }
}
