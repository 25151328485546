/* eslint-disable @typescript-eslint/no-explicit-any */
// error-modal.component.ts
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorModalService, ModalData } from '../../services/error-modal.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
    standalone: false
})
export class ErrorModalComponent implements AfterViewInit, OnDestroy {
  isModalOpen = false;
  private modalContainer: any;
  private subscription!: Subscription;

  modalTitle = '';
  closeButtonLabel: string | undefined = '';
  saveButtonLabel: string | undefined = '';
  content = '';
  url!: string | undefined;

  constructor(private elementRef: ElementRef, private modalService: ErrorModalService, private router: Router) {}

  ngAfterViewInit(): void {
    this.modalContainer = this.elementRef.nativeElement.querySelector('.modal-background');

    this.subscription = this.modalService.showModal$.subscribe((data: ModalData | undefined) => {
      if (data) {
        this.modalTitle = data.title;
        this.closeButtonLabel = data.closeButtonLabel;
        this.saveButtonLabel = data.saveButtonLabel;
        this.content = data.content;
        this.isModalOpen = true;
        this.url = data.url;
      } else {
        this.isModalOpen = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.modalContainer && !this.modalContainer.contains(event.target)) {
      this.closeModal();
    }
  }

  saveChanges(): void {
    // Implementa la lógica para guardar cambios aquí
    this.modalService.closeModal();
  }

  goTo(): void {
    if(this.url){
      if(this.url.includes("http")){
        window.open(this.url);
      }
    }else{
      this.router.navigate([this.url || '/']);
    }

    this.modalService.closeModal();
  }
}
