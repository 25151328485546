/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CreditService } from '../../../../services/credit.service';
import {
    PrestamosCalculos,
    PrestamosCalculosResponse,
    CreditData,
} from '../../../../models/credit-simulator-interface';
import { catchError, concatMap, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest, lastValueFrom, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SecureStorageService } from 'src/app/services/secure-storage.service';
import { environment } from 'src/app/enviroments/enviroment';
import { AppModules, CreditRequestRoutes } from 'src/app/models/enums/routes.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { CreditErrorType } from 'src/app/models/enums/credit-error.enum';
import { IParser, IParserToken } from 'src/app/interfaces/parse-service.interface';
import { ICryptoToken, ICrypto } from 'src/app/interfaces/encryption-service.interface';
import { AuthenticatedUserData } from 'src/app/models/authenticated-user-data.interface';
import { LoggerService } from 'src/app/services/logger.service';
import { ResizeService } from 'src/app/services/resize.service';
import { MessageService } from 'src/app/services/message.service';
import { QueryService } from 'src/app/services/query.service';
import { InitialCreditValues } from 'src/app/models/initial-credit-values.interface';
import { GiveawayService } from 'src/app/services/giveaway.service';

@Component({
    selector: 'app-credit-simulator',
    templateUrl: './credit-simulator.component.html',
    styleUrls: ['./credit-simulator.component.scss'],
    standalone: false
})
export class CreditSimulatorComponent implements OnInit, OnDestroy {
    @ViewChild('appContainer', { static: true, read: ElementRef }) appContainer!: ElementRef;
    logged = false;

    imageTemplate = '';
    data?: PrestamosCalculosResponse;
    isDisabled = false;

    sliderData!: PrestamosCalculos;
    interes!: number;
    total!: number;
    returnDate!: string;

    minAmount = 0;
    maxAmount = 0;
    stepAmount = 10;

    minDays = 0;
    maxDays = 0;
    stepDays = 0;
    dias = 0;
    amount = 0;

    private destroyRef$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private authService: AuthService,
        private creditService: CreditService,
        private router: Router,
        private secureStorage: SecureStorageService,
        private spinner: NgxSpinnerService,
        private dataService: DataService,
        private route: ActivatedRoute,
        private logger: LoggerService,
        @Inject(ICryptoToken) private crypto: ICrypto,
        @Inject(IParserToken) private parser: IParser,
        private secureStore: SecureStorageService,
        private resize: ResizeService,
        private message: MessageService,
        private queryService: QueryService,
        private giveaway: GiveawayService,
    ) { }

    ngOnInit(): void {
        this.secureStorage.storeEncrypted(environment.loggedTokenKey, null);
        this.secureStorage.storeEncrypted(environment.loggedData, null);
        this.secureStorage.storeEncrypted(environment.loggedTokenKey, null);
        this.dataService.setLoggedByUrl(false);

        this.checkIfUserIsLogged();
        this.setView();
        this.resize
            .getHeightObservable(this.appContainer.nativeElement)
            .pipe(takeUntil(this.destroyRef$))
            .subscribe((height) => this.message.send({ type: 'height', message: height }));
    }

    private async checkIfUserIsLogged(): Promise<void> {
        this.route.queryParamMap.subscribe((params) => {
            const data = params.get('q');
            if (data !== null) this.authenticateUser(data);
        });
    }

    private checkIfGiveaway(): Observable<{ valid: boolean; code?: string }> {
        return this.route.queryParamMap.pipe(
            map((params) => params.get('c')),
            mergeMap((code) =>
                code
                    ? this.giveaway.checkIfUrlIsUsed(code).pipe(map((res) => ({ valid: res.valid, code: res.data.link })))
                    : of({ valid: false }),
            ),
        );
    }

    private async checkGiveAwayCode(code: string): Promise<void> {
        const data = await lastValueFrom(this.giveaway.checkIfUrlIsUsed(code));
        if (data.valid) {
            this.dataService.setLink(code);
        }
    }

    private async authenticateUser(cypheredData: string): Promise<void> {
        const decyphered = await this.crypto.decrypt(decodeURIComponent(cypheredData));
        const userData = this.parser.parse<AuthenticatedUserData>(decyphered);
        this.secureStorage.storeEncrypted(environment.loggedData, decyphered);
        await this.secureStore.storeEncrypted(environment.loggedTokenKey, userData.token);
        this.dataService.setLoggedByUrl(true);
        this.logged = true;
    }

    async setView(): Promise<void> {
        this.isDisabled = true;
        this.spinner.show('Solicitando datos del crédito...');
        try {
            const token = await this.authService.getToken();
            await this.secureStorage.storeEncrypted(environment.getTokenKey, token.data.token);

            combineLatest([
                this.queryService.getParamsFromRoute<InitialCreditValues>(this.route),
                this.checkIfGiveaway().pipe(
                    catchError((): Observable<{ valid: boolean; code?: string }> => of({ valid: false })),
                    tap((valid) => (valid.valid && valid.code ? this.dataService.setLink(valid.code) : undefined)),
                    concatMap((valid) =>
                        this.creditService
                            .getLoanCalculations(valid.valid ? environment.giveawayLineaId : environment.defaultLineaId)
                            .pipe(catchError(() => this.creditService.getLoanCalculations())),
                    ),
                ),
            ])
                .pipe(takeUntil(this.destroyRef$))
                .subscribe(([initialCreditValues, prestamosCalculos]) => {
                    this.getLoanCalculations(prestamosCalculos);
                    if (Object.values(initialCreditValues).length > 0)
                        setTimeout(
                            () => this.calculateLoan(initialCreditValues as InitialCreditValues, prestamosCalculos.data.calculos),
                            500,
                        );
                });
        } catch (error: any) {
            this.logger.error(error, 'Credit simulator 145');
            this.router.navigate([
                AppModules.CREDIT_REQUEST,
                CreditRequestRoutes.ERROR,
                CreditErrorType.GENERIC,
                'GET_TOKEN',
            ]);
        }
    }

    private getLoanCalculations(response: PrestamosCalculosResponse): void {
        this.sliderData = response.data;
        this.returnDate = formatDate(new Date(), 'yyyy/MM/dd', 'en-US');

        [this.minAmount, this.maxAmount] = [
            this.sliderData.importes[0],
            this.sliderData.importes[this.sliderData.importes.length - 1],
        ];

        [this.minDays, this.maxDays] = [this.sliderData.dias[0], this.sliderData.dias[this.sliderData.dias.length - 1]];

        this.amount = this.minAmount;
        this.total = this.amount;

        this.updateCreditValues();
        this.isDisabled = false;
        this.spinner.hide();
    }

    private updateCreditValues(): void {
        const credit: CreditData = {
            importe: this.amount,
            importe_total: this.total,
            interes: this.interes,
            dias: this.dias,
            fecha_devolucion: this.returnDate,
            acepta_terminos_condiciones: false,
        };

        this.dataService.setCreditData(credit);
    }

    getDataSlider(importe?: number, dias?: number): void {
        const INTERES_POR_DEFECTO = 0;
        const TOTAL_POR_DEFECTO = 0;

        if (typeof importe === 'number') {
            this.amount = importe;
        }
        if (typeof dias === 'number') {
            this.dias = dias;
        }

        const calculos = this.sliderData?.calculos[this.amount]?.[this.dias];
        calculos
            ? this.updateProperties(calculos.interes, calculos.total)
            : this.updateProperties(INTERES_POR_DEFECTO, TOTAL_POR_DEFECTO);
        if (this.sliderData && this.sliderData.fechas_devolucion) {
            this.returnDate = this.sliderData.fechas_devolucion[this.dias];
        }

        this.updateCreditValues();
    }

    private updateProperties(interes: number, total: number): void {
        this.interes = interes;
        this.total = total;
    }

    private calculateLoan(response: InitialCreditValues, calculos: any): void {
        const calculo = calculos[response.amount] ? calculos[response.amount][response.days] : undefined;
        if (calculo) {
            this.amount = response.amount;
            this.total = calculo.total;
            this.interes = calculo.interes;
            this.dias = response.days;
            this.returnDate = this.sliderData.fechas_devolucion[this.dias];
            this.isDisabled = false;
            this.getCredit();
        }
    }

    getCredit(): void {
        if (!this.isDisabled) {
            const credit: CreditData = {
                importe: this.amount,
                importe_total: this.total,
                interes: this.interes,
                dias: this.dias,
                fecha_devolucion: this.returnDate,
                acepta_terminos_condiciones: false, //TODO hacerlo
            };

            this.dataService.setCreditData(credit);
            this.router.navigate([
                AppModules.CREDIT_REQUEST,
                this.logged ? CreditRequestRoutes.BANKING_DATA : CreditRequestRoutes.CREDIT_INFO,
            ]);
        }
    }

    ngOnDestroy(): void {
        this.destroyRef$.next(true);
        this.destroyRef$.unsubscribe();
    }
}
