import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    warn(...params: unknown[]): void {
        this.log('%cWarning', 'background-color: orange; padding: 2px 4px; font-weight: bold;', ...params);
    }

    error(...params: unknown[]): void {
        Sentry.captureException(params);
        this.log('%cError', 'background-color: red; padding: 2px 4px; font-weight: bold;', ...params);
    }

    message(msg: string): void {
        Sentry.captureMessage(msg);
        //this.log('%cError', 'background-color: red; padding: 2px 4px; font-weight: bold;', msg);
    }

    info(...params: unknown[]): void {
        this.log('%cInfo', 'background-color: lightblue; padding: 2px 4px; font-weight: bold;', ...params);
    }

    private log(type: '%cWarning' | '%cError' | '%cInfo', style: string, ...items: unknown[]): void {
        // eslint-disable-next-line no-console
        console.log(`${type}:`, style, ...items);
    }
}
