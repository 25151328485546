/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/angular';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EMPTY,
    Observable,
    Subject,
    catchError,
    exhaustMap,
    finalize,
    from,
    mergeMap,
    of,
    shareReplay,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { environment } from '../enviroments/enviroment';
import { AuthService } from '../services/auth.service';
import { SecureStorageService } from '../services/secure-storage.service';
import { Router } from '@angular/router';
import { ErrorModalService } from '../services/error-modal.service';
import { AppModules, CreditRequestRoutes } from '../models/enums/routes.enum';
import { CreditErrorType } from '../models/enums/credit-error.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: Subject<string | null> = new Subject();
    private refreshUrls = [
        'formularios/estados-civiles',
        'formularios/provincias',
        'formularios/empleos',
        'formularios/motivos-prestamos',
        'existe-cliente',
        'login',
        'registro',
    ].map((url) => `${environment.apiUrl}/${url}`);

    private creditUrls = [];

    constructor(
        private authService: AuthService,
        private secureStorage: SecureStorageService,
        private router: Router,
        private modalService: ErrorModalService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((res) => {
                if (res instanceof HttpResponse && (res.body.result === 'rejected' || res.body.approved === false)) {
                    // Sentry.captureException(res.body.toString());
                    const errorDetails = JSON.stringify(res.body);

                    // Agregar contexto adicional
                    Sentry.setContext('HTTP Response', {
                        body: res.body,
                        status: res.status,
                        statusText: res.statusText,
                    });

                    Sentry.captureException(new Error(errorDetails));

                    const errorType = this.getErrorType(res);
                    const getErrorId = this.getErrorId(res);
                    this.router.navigate([AppModules.CREDIT_REQUEST, CreditRequestRoutes.ERROR, errorType, getErrorId]);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                const errorDetails = JSON.stringify({
                    message: error.message,
                    name: error.name,
                    status: error.status,
                    statusText: error.statusText,
                    url: error.url,
                    error: error.error, // Aquí está el cuerpo de la respuesta de error
                });

                // Agregar contexto adicional
                Sentry.setContext('HTTP Error Response', {
                    message: error.message,
                    name: error.name,
                    status: error.status,
                    statusText: error.statusText,
                    url: error.url,
                    error: error.error, // Aquí está el cuerpo de la respuesta de error
                });

                // Capturar la excepción con los detalles del error
                Sentry.captureException(new Error(errorDetails));

                if (req.url.includes('solicitud/pagar')) {
                    this.router.navigate([AppModules.CREDIT_REQUEST, CreditRequestRoutes.REQUEST_CREDIT_RESOLUTION]);
                } else if (error.status === 401 && this.refreshUrls.includes(req.url)) {
                    if (!this.isRefreshing) {
                        this.isRefreshing = true;
                        this.refreshTokenSubject.next(null);

                        return from(this.authService.getToken()).pipe(
                            mergeMap((token) => {
                                this.isRefreshing = false;
                                this.refreshTokenSubject.next(token.data.token);
                                from(this.secureStorage.storeEncrypted(environment.getTokenKey, token.data.token));
                                return of(token);
                            }),
                            exhaustMap((token) =>
                                next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${token.data.token}` } })),
                            ),
                        );
                    }

                    return this.refreshTokenSubject.pipe(
                        switchMap((token) => next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }))),
                        finalize(() => this.refreshTokenSubject.next(null)),
                        shareReplay(),
                    );
                } else if ([0, 500, 400, 403, 409].includes(error.status) && !error.url!.includes('code-signin'))
                    return this.manageHttpError(error);

                return throwError(() => new Error(error.message));
            }),
        );
    }

    private getErrorType(response: HttpResponse<unknown>): CreditErrorType {
        let error = CreditErrorType.GENERIC;
        if (response.url?.includes('solicitud/crear')) error = CreditErrorType.CREATE;
        else if (response.url?.includes('risk/fraud') || response.url?.includes('risk/prevention'))
            error = CreditErrorType.RISK;
        else if (response.url?.includes('risk/bank/scoring')) error = CreditErrorType.SCORE;
        else if (response.url?.includes('risk/bank/widget')) error = CreditErrorType.BANK;
        return error;
    }

    private getErrorId(response: HttpResponse<unknown>): string {
        let error = 'Error de servidor';
        if (response.url?.includes('solicitud/crear')) error = 'Error de solicitud';
        else if (response.url?.includes('risk/fraud') || response.url?.includes('risk/prevention'))
            error = 'Validación de riesgo';
        else if (response.url?.includes('risk/bank/scoring')) error = 'Validación de puntaje';
        else if (response.url?.includes('risk/bank/widget')) error = 'Validación bancaria';
        return error;
    }

    private manageHttpError(error: HttpErrorResponse): Observable<any> {
        switch (error.status) {
            case 0:
                this.manageNoConnectionError();
                break;
            case 500:
                this.manage500Error();
                break;
            case 400:
                this.manage400Error(error);
                break;
            case 409:
                this.manage409Error(error);
                break;
            default:
                this.router.navigate([
                    AppModules.CREDIT_REQUEST,
                    CreditRequestRoutes.ERROR,
                    CreditErrorType.GENERIC,
                    'Error de servidor',
                ]);
        }
        return EMPTY;
    }

    private async manage409Error(error: HttpErrorResponse): Promise<void> {
        const message: string = error.error.message;
        if (message.includes('Tiene préstamos')) {
            this.router.navigate([
                AppModules.CREDIT_REQUEST,
                CreditRequestRoutes.CUSTOM_ERROR,
                CreditErrorType.HAVE_LOAN,
                'Ya posee un préstamo activo.',
            ]);
        } else if (message.includes('Debe esperar')) {
            this.router.navigate([
                AppModules.CREDIT_REQUEST,
                CreditRequestRoutes.ERROR,
                CreditErrorType.SCORE,
                'Fallo en validación de score crediticio',
            ]);
        } else if (message.includes('mora')) {
            this.router.navigate([AppModules.CREDIT_REQUEST, CreditRequestRoutes.ERROR, CreditErrorType.RISK, message]);
        }
    }

    private async manage400Error(error: HttpErrorResponse): Promise<void> {
        if (error.error.mensaje && typeof error.error.mensaje === 'string') {
            const mensaje: string = error.error.mensaje;
            if (mensaje == 'El usuario tiene solicitudes activas.') {
                this.router.navigate([
                    AppModules.CREDIT_REQUEST,
                    CreditRequestRoutes.CUSTOM_ERROR,
                    CreditErrorType.HAVE_LOAN,
                    'Ya posee un préstamo activo.',
                ]);
            } else if (
                mensaje.includes('Ya existe') ||
                mensaje.includes('no es válido') ||
                mensaje.includes('pudimos validar')
            ) {
                this.openErrorSnackbar(mensaje, 'close');
            } else if (mensaje.includes('El usuario tiene solicitudes activas.')) {
                this.router.navigate([
                    AppModules.CREDIT_REQUEST,
                    CreditRequestRoutes.ERROR,
                    CreditErrorType.HAVE_LOAN,
                    'Tiene solicitudes',
                ]);
            }
        } else if (error.error.mensaje && typeof error.error.mensaje === 'object') {
            const messages: { [key: string]: Array<string> } = error.error.mensaje;
            const text = Object.values(messages)
                .map((message) => message[0])
                .reduce((prev, curr) => `${prev} ${curr}`);

            this.openErrorSnackbar(text, 'close');
        }
    }

    private manage500Error(): void {
        this.router.navigate([AppModules.CREDIT_REQUEST, CreditRequestRoutes.ERROR, CreditErrorType.GENERIC, '']);
    }

    private manageNoConnectionError(): void {
        this.router.navigate([AppModules.CREDIT_REQUEST, CreditRequestRoutes.ERROR, CreditErrorType.NO_NETWORK, '']);
    }

    private openErrorSnackbar(error: string, action: string, title = '¡Oops! Ha ocurrido un error'): void {
        this.modalService.openModal({
            content: error,
            title,
            closeButtonLabel: action,
        });
    }
}
