import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonSize, ButtonType } from './button.type';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    imports: [CommonModule],
    standalone: true
})
export class ButtonComponent {
    @Input() type: ButtonType = 'button';
    @Input() size: ButtonSize = 'big';
    @Input() loading = false;

    @Output() clicked = new EventEmitter();

    @HostBinding('class.disabled')
    @Input()
    disabled = false;

    handleClick(): void {
        if (this.disabled || this.loading) return;
        this.clicked.emit();
    }
}
