import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { AppModules, CreditRequestRoutes } from 'src/app/models/enums/routes.enum';
import { MessageService } from 'src/app/services/message.service';
import { ResizeService } from 'src/app/services/resize.service';

@Component({
    selector: 'app-credit-request-layout',
    templateUrl: './credit-request-layout.component.html',
    styleUrls: ['./credit-request-layout.component.scss'],
    standalone: false
})
export class CreditRequestLayoutComponent implements OnDestroy, AfterViewInit {
  @ViewChild('appContainer', { static: true, read: ElementRef }) appContainer!: ElementRef;

  private resizeObserver?: ResizeObserver;

  public showSumary$: Observable<boolean | null> = this.router.events.pipe(
    filter((ev) => ev instanceof NavigationEnd),
    map((ev) => ev as NavigationEnd),
    map((ev) => !this.hiddenRoutes.includes(ev.url)),
  );
  private hiddenRoutes = [
    `/${AppModules.CREDIT_REQUEST}/${CreditRequestRoutes.REQUEST_CREDIT_RESOLUTION}`,
    `/${AppModules.CREDIT_REQUEST}/${CreditRequestRoutes.REQUEST_CREDIT_CHECK}`,
  ];

  constructor(private router: Router, private resize: ResizeService, private message: MessageService) {}

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const height = entry.contentRect.height;
        this.message.send({ type: 'height', message: height });
      }
    });

    this.resizeObserver.observe(this.appContainer.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }
}
