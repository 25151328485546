import { Options } from '@angular-slider/ngx-slider/options';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-slider-agil-cred',
    templateUrl: './slider-agil-cred.component.html',
    styleUrls: ['./slider-agil-cred.component.scss'],
    standalone: false
})
export class SliderAgilCredComponent implements OnInit, OnChanges {
  @Input() title = '¿Cuánto dinero necesitas?';
  @Input() value = 0;
  @Input() min = 0;
  @Input() max = 0;
  @Input() step = 0;
  @Input() isCash = true;
  @Input() sub_title = '';
  @Output() values = new EventEmitter<number>();

  options: Options = {};

  ngOnInit(): void {
    this.setView();
  }

  setView(): void {
    this.options = {
      floor: this.min,
      ceil: this.max,
      showSelectionBar: true,
      step: this.step,
    };
  }

  getValue(event: number): void {
    this.values.emit(event);
  }

  ngOnChanges(): void {
    this.setView();
  }
}
